import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useStaticQuery, graphql } from "gatsby"

import { ThemeProvider } from "@material-ui/styles"
// import { createMuiTheme } from "@material-ui/core/styles"
import { createTheme } from '@material-ui/core/styles'
import { CssBaseline, Box } from "@material-ui/core"

import Header from "../components/header"
import Map from "../components/map"
import Password from "../components/password"

import { useDispatchPoints } from "../functions/use-dispatch-points"

const theme = createTheme({
  palette: {
    // primary: {
    //   light: "#86c0c6",
    //   main: "#2b5963",
    //   dark: "#1e3e45",
    //   contrastText: "#fff",
    // },
    // secondary: {
    //   light: "#b09c4c",
    //   main: "#9D8420",
    //   dark: "#6d5c16",
    //   contrastText: "#fff",
    // },
    primary: {
      light: "#4d91be",
      main: "#2176AE",
      dark: "#175279",
      contrastText: "#fff",
    },
    secondary: {
      light: "#f8a94b",
      main: "#F7941E",
      dark: "#ac6715",
      contrastText: "#000",
    },
  },
})

const Layout = ({ children }) => {
  useEffect(() => {
    console.log("=== Layout ===")
  })

  const dispatch = useDispatch()

  const { countries } = useStaticQuery(
    graphql`
      query {
        countries: allCountriesJson(
          filter: { properties: { id: { ne: null } } }
        ) {
          nodes {
            bbox
            properties {
              id
              isoAlpha2
              nameFr
            }
          }
        }
      }
    `
  )

  // START : useEffect()

  // Set countries points
  useDispatchPoints()

  // Set all countries
  useEffect(() => {
    let nextCountries = []
    countries.nodes.forEach(node => {
      nextCountries = [
        ...nextCountries,
        { ...node.properties, bbox: node.bbox },
      ]
    })
    console.log("SET_COUNTRIES")
    dispatch({
      type: "SET_COUNTRIES",
      countries: nextCountries || null,
    })
  }, [dispatch, countries])
  // START : useEffect()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box display="flex" flexDirection="column" height="100vh">
        <Header>{children}</Header>
        <Map>{children}</Map>
      </Box>
      <Password
        tooltip="Ouvrir le glossaire"
        title="Glossaire : types d'oeuvres"
      >
        <Box textAlign="left">password</Box>
      </Password>
    </ThemeProvider>
  )
}

export default Layout
