import React, { useState } from "react"

import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core"

const Password = () => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    if (password === "weblitt2021") {
      setOpen(false)
    } else {
      setError(true)
    }
  }

  const [password, setPassword] = useState(null)
  const [error, setError] = useState(false)

  const handleChange = e => {
    setPassword(e.target.value)
  }

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const lastDate = localStorage.getItem("date")
  //     const nowDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
  //     localStorage.setItem("date", nowDate)
  //     if (!lastDate) {
  //       console.log("First visit !")
  //       setOpen(true)
  //     } else {
  //       const daysDiff = differenceInCalendarDays(
  //         parseISO(nowDate),
  //         parseISO(lastDate)
  //       )
  //       // console.log("Days from last visit", daysDiff)
  //       if (daysDiff >= 1) {
  //         // console.log("Open popup (daysDiff >= 1)")
  //         setOpen(true)
  //       } else {
  //         // console.log("Ignore popup (daysDiff < 1)")
  //       }
  //     }
  //   }
  // }, [])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xs">
      <Box textAlign="center">
        <DialogTitle disableTypography>
          <Typography variant="h4">Accès réservé</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Renseigner le mot de passe pour accéder au contenu
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Mot de passe"
            type="password"
            fullWidth
            variant="outlined"
            onChange={handleChange}
            helperText={error && "Mot de passe incorrecte"}
            error={error}
            onKeyPress={e => {
              if (e.key === "Enter") {
                handleClose()
              }
            }}
          />
        </DialogContent>
      </Box>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button onClick={handleClose} color="primary">
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Password
