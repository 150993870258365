import React from "react"

import { Link as RouterLink } from "gatsby"
import { Link as StyleLink } from "@material-ui/core/"

const Link = ({ to, color, children }) => (
  <StyleLink component={RouterLink} to={to} color={color}
  onClick={() => {
    // console.log('click');
  }}
  >
    {children}
  </StyleLink>
)

export default Link
