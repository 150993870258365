import React, { Fragment, useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"

import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

import * as turf from "@turf/turf"

// import { amber } from "@material-ui/core/colors"
import { useTheme, makeStyles } from "@material-ui/styles"
import {
  Box,
  Fab,
  CircularProgress,
  IconButton,
  Hidden,
} from "@material-ui/core"
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  Close as CloseIcon,
  KeyboardArrowUp as ChevronUpIcon,
  KeyboardArrowDown as ChevronDownIcon,
  KeyboardArrowRight as ChevronRightIcon,
  KeyboardArrowLeft as ChevronLeftIcon,
} from "@material-ui/icons"

import Search from "./search"

import labels from "../data/labels.json"
import countries from "../data/countries.json"

const useStyles = makeStyles(theme => ({
  button: {
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    "&:hover": {
      backgroundColor: "black",
    },
  },
}))

const Map = ({ children }) => {
  useEffect(() => {
    console.log("=== Map ===")
  })

  const theme = useTheme()
  const classes = useStyles()

  const dispatch = useDispatch()
  const display = useSelector(state => state.display)

  const [loading, setLoading] = useState(true)
  const [map, setMap] = useState(null)
  const mapContainer = useRef(null)

  const work = useSelector(state => state.work)
  const country = useSelector(state => state.country)
  const countriesPoints = useSelector(state => state.countriesPoints)

  useEffect(() => {
    if (map && countriesPoints) {
      // console.log("countriesPoints", countriesPoints)

      if (map.getSource("countriesPointsSource")) {
        map.removeLayer("countriesPointsLayer")
        map.removeLayer("countriesPointsTextLayer")
        map.removeSource("countriesPointsSource")
      }

      map.addSource("countriesPointsSource", {
        type: "geojson",
        data: turf.featureCollection(countriesPoints),
        cluster: true,
        clusterRadius: 0,
        clusterProperties: {
          sum: ["+", ["get", "total", ["properties"]]],
        },
      })
      map.addLayer({
        id: "countriesPointsLayer",
        type: "circle",
        source: "countriesPointsSource",
        paint: {
          "circle-radius": 15,
          "circle-color": theme.palette.primary.dark,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#ffffff",
        },
      })
      map.addLayer({
        id: "countriesPointsTextLayer",
        type: "symbol",
        source: "countriesPointsSource",
        layout: {
          "text-field": [
            "case",
            ["!=", ["get", "sum"], null],
            ["get", "sum"],
            ["get", "total"],
          ],
          "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
          "text-size": 11,
        },
        paint: {
          "text-color": "#ffffff",
        },
      })
    }
  }, [map, countriesPoints, theme])

  const [bbox, setBbox] = useState(undefined)

  useEffect(() => {
    if (map) {
      map.resize()
    }
  }, [map, display])

  // START : useEffect()
  useEffect(() => {
    if (countries && country) {
      const bbox = countries.find(node => node.properties.id === country.id)
        ?.bbox // <=================================================== automatic bbox in feature node
      setBbox(bbox)
    } else {
      setBbox([-81, -55, 103, 83]) // Default world bbox
    }
  }, [country])
  // STOP : useEffect()

  // START : useEffect()
  useEffect(() => {
    // START : initializeMap
    const initializeMap = ({ setMap, mapContainer }) => {
      mapboxgl.accessToken =
        "pk.eyJ1IjoiYWxnb3VzIiwiYSI6ImNqZWN3dG1zNDBvdXgzM21rdDZwd2pyMTIifQ.0H0XitL8AcIIRUIkDU66uQ"

      const map = new mapboxgl.Map({
        container: mapContainer.current,
        // style: "mapbox://styles/mapbox/light-v10",
        style: {
          version: 8,
          sources: {
            // "country-boundaries": {
            //   "type": "vector",
            //   "url": "mapbox://mapbox.country-boundaries-v1"
            // }
          },
          layers: [
            {
              id: "background",
              type: "background",
              paint: {
                // "background-color": theme.palette.primary.light,
                "background-color": theme.palette.primary.dark,
              },
            },
            // {
            //   "id": "undisputed country boundary fill",
            //   "source": "country-boundaries",
            //   "source-layer": "country_boundaries",
            //   "type": "fill",
            //   "filter": [
            //     "==",
            //     [
            //       "get",
            //       "disputed"
            //     ],
            //     "false"
            //   ],
            //   "paint": {
            //     "fill-color": "rgba(66,100,251, 0.3)",
            //     "fill-outline-color": "#0000ff"
            //   }
            // },
            // {
            //   "id": "disputed area boundary fill",
            //   "source": "country-boundaries",
            //   "source-layer": "country_boundaries",
            //   "type": "fill",
            //   "filter": [
            //     "==",
            //     [
            //       "get",
            //       "disputed"
            //     ],
            //     "true"
            //   ],
            //   "paint": {
            //     "fill-color": "rgba(200,100,251, 0.3)",
            //     "fill-outline-color": "#ff0000"
            //   }
            // },
          ],
          glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
        },
        zoom: 1.2,
        center: [4.175332, 48.132767],
      })

      // START : map.on("load")
      map.on("load", () => {
        setMap(map)
        map.resize()

        map.addSource("countriesSource", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: countries,
          },
          generateId: true,
        })
        map.addLayer({
          id: "countriesLayer",
          type: "fill",
          source: "countriesSource",
          // minzoom: 2,
          // maxzoom: 12,
          paint: {
            // "fill-outline-color": theme.palette.primary.main,
            "fill-outline-color": theme.palette.primary.dark,
          },
        })

        map.addSource("labelsSource", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: labels,
          },
          generateId: true,
        })
        map.addLayer({
          id: "labelsLayer",
          type: "symbol",
          source: "labelsSource",
          minzoom: 4,
          maxzoom: 12,
          paint: {
            "text-color": "#000000",
          },
          layout: {
            "text-field": ["get", "nameFr"],
            "text-variable-anchor": ["top", "bottom", "left", "right"],
            "text-radial-offset": 0.8,
          },
        })

        setFillColor(map)
        fitToActive(map)

        const onLayerActions = (map, layer, source) => {
          let hoveredFeature = null
          map.on("mousemove", layer, e => {
            map.getCanvas().style.cursor = "pointer"
            if (e.features.length > 0) {
              if (hoveredFeature !== undefined) {
                map.setFeatureState(
                  {
                    source,
                    id: hoveredFeature,
                  },
                  {
                    hover: false,
                  }
                )
              }
              hoveredFeature = e.features[0].id
              map.setFeatureState(
                {
                  source,
                  id: hoveredFeature,
                },
                {
                  hover: true,
                }
              )
            }
          })
          map.on("mouseleave", layer, () => {
            map.getCanvas().style.cursor = ""
            map.setFeatureState(
              {
                source,
                id: hoveredFeature,
              },
              {
                hover: false,
              }
            )
          })
          map.on("click", layer, e => {
            console.log("SET_DISPLAY")
            dispatch({
              type: "SET_DISPLAY",
              display: {
                ...display,
                sidebar: true,
              },
            })
            navigate("/" + e.features[0].properties.id)
          })
        }

        onLayerActions(map, "countriesLayer", "countriesSource")
        // onLayerActions("areasLayer", "areasSource")

        // map.on("click", "undisputed country boundary fill", e => {
        //   console.log(e.features[0].properties)
        // })
      })
      // STOP : map.on("load")
    }
    // STOP : initializeMap

    // START : setFillColor
    const setFillColor = map => {
      const { palette } = theme

      const enablecountry = () => {
        map.setPaintProperty("countriesLayer", "fill-color", [
          "case",
          ["==", ["get", "id"], country.id],
          // palette.primary.dark,
          palette.secondary.main,
          ["boolean", ["feature-state", "hover"], false],
          // palette.primary.dark,
          palette.secondary.main,
          // palette.grey[200],
          // amber[100],
          palette.primary.main,
        ])
      }
      const disablecountry = () => {
        map.setPaintProperty("countriesLayer", "fill-color", [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          // palette.primary.dark,
          palette.secondary.main,
          // palette.grey[200],
          // amber[100],
          palette.primary.main,
        ])
      }

      if (country) {
        enablecountry()
      } else {
        disablecountry()
      }
    }
    // STOP : setFillColor

    // START : fitToActive
    const fitToActive = map => {
      if (bbox !== undefined) {
        const paddingRatio = 0.05
        map.fitBounds(bbox, {
          padding: {
            left: window.innerWidth * paddingRatio,
            right: window.innerWidth * paddingRatio,
            top: window.innerHeight * paddingRatio,
            bottom: window.innerHeight * paddingRatio,
          },
        })
      }
    }
    // START : fitToActive

    if (!map) {
      initializeMap({ setMap, mapContainer })
    } else {
      setFillColor(map)
      fitToActive(map)

      // map.on("moveend", () => {
      //   // Get map bounds
      //   const { _sw, _ne } = map.getBounds()

      //   // Create LngLat points
      //   const NE = [_ne.lng, _ne.lat]
      //   const SE = [_ne.lng, _sw.lat]
      //   const SW = [_sw.lng, _sw.lat]
      //   const NW = [_sw.lng, _ne.lat]

      //   // Convert LngLat to Pixel
      //   const NEpx = map.project(NE)
      //   const SEpx = map.project(SE)
      //   const SWpx = map.project(SW)
      //   const NWpx = map.project(NW)

      //   // Add px padding
      //   const paddingRatio = 0.05
      //   const nextNEpx = {
      //     x: NEpx.x - window.innerWidth * paddingRatio,
      //     y: NEpx.y + window.innerHeight * paddingRatio,
      //   }
      //   const nextSEpx = {
      //     x: SEpx.x - window.innerWidth * paddingRatio,
      //     y: SEpx.y - window.innerHeight * paddingRatio,
      //   }
      //   const nextSWpx = {
      //     x: SWpx.x + window.innerWidth * paddingRatio,
      //     y: SWpx.y - window.innerHeight * paddingRatio,
      //   }
      //   const nextNWpx = {
      //     x: NWpx.x + window.innerWidth * paddingRatio,
      //     y: NWpx.y + window.innerHeight * paddingRatio,
      //   }

      //   // Convert Pixel to LngLat
      //   let nextNE = map.unproject(nextNEpx)
      //   let nextSE = map.unproject(nextSEpx)
      //   let nextSW = map.unproject(nextSWpx)
      //   let nextNW = map.unproject(nextNWpx)
      //   nextNE = [nextNE.lng, nextNE.lat]
      //   nextSE = [nextSE.lng, nextSE.lat]
      //   nextSW = [nextSW.lng, nextSW.lat]
      //   nextNW = [nextNW.lng, nextNW.lat]

      //   const mapBoundsPolygon = turf.polygon([
      //     [nextNE, nextSE, nextSW, nextNW, nextNE],
      //   ])

      //   console.log(turf.bbox(mapBoundsPolygon))

      //   // if (map.getSource("mapBoundsSource")) {
      //   //   map.removeLayer("mapBoundsLayer")
      //   //   map.removeSource("mapBoundsSource")
      //   // }
      //   // map.addSource("mapBoundsSource", {
      //   //   type: "geojson",
      //   //   data: mapBoundsPolygon,
      //   // })
      //   // map.addLayer({
      //   //   id: "mapBoundsLayer",
      //   //   type: "fill",
      //   //   source: "mapBoundsSource",
      //   //   layout: {},
      //   //   paint: {
      //   //     "fill-color": "transparent",
      //   //     "fill-outline-color": "red",
      //   //   },
      //   // })

      //   // let bboxFeatures = []
      //   // areasCollection.features.forEach(feature => {
      //   //   if (feature.properties.type === "continent") {
      //   //     bboxFeatures = [
      //   //       ...bboxFeatures,
      //   //       turf.bboxPolygon(
      //   //         continentsData.find(
      //   //           continent => continent.id === feature.properties.id
      //   //         ).bbox
      //   //       ),
      //   //     ]
      //   //   }
      //   // })
      //   // const bboxCollection = turf.featureCollection(bboxFeatures)

      //   // if (map.getSource("bboxSource")) {
      //   //   map.removeLayer("bboxLayer")
      //   //   map.removeSource("bboxSource")
      //   // }
      //   // map.addSource("bboxSource", {
      //   //   type: "geojson",
      //   //   data: bboxCollection,
      //   // })
      //   // map.addLayer({
      //   //   id: "bboxLayer",
      //   //   type: "fill",
      //   //   source: "bboxSource",
      //   //   layout: {},
      //   //   paint: {
      //   //     "fill-color": "transparent",
      //   //     "fill-outline-color": "lightgrey",
      //   //   },
      //   // })

      //   // const isInviewContinent = areasCollection.features
      //   //   .filter(feature => feature.properties.type === "continent")
      //   //   .some(feature =>
      //   //     turf.booleanWithin(
      //   //       turf.bboxPolygon(
      //   //         continentsData.find(
      //   //           continent => continent.id === feature.properties.id
      //   //         ).bbox
      //   //       ),
      //   //       mapBoundsPolygon
      //   //     )
      //   //   )
      //   // // console.log("isInviewContinent", isInviewContinent)

      //   // if (isInviewContinent) {
      //   //   map.setFilter("areasLayer", ["==", "type", "continent"])
      //   // } else {
      //   //   const isInviewCountry = areasCollection.features
      //   //     .filter(feature => feature.properties.type === "country")
      //   //     .some(feature =>
      //   //       turf.booleanWithin(turf.bboxPolygon(feature.bbox), mapBoundsPolygon)
      //   //     )
      //   //   // console.log("isInviewCountry", isInviewCountry)

      //   //   if (isInviewCountry) {
      //   //     map.setFilter("areasLayer", ["==", "type", "country"])
      //   //   }
      //   // }
      // })

      // START : onceDataLoaded
      const onceDataLoaded = () => {
        if (map.getSource("countriesSource")) {
          if (map.isSourceLoaded("countriesSource")) {
            map.off("sourcedata", onceDataLoaded)
            setLoading(false)
          }
        }
      }
      map.on("sourcedata", onceDataLoaded)
      // STOP : onceDataLoaded
    }
  }, [dispatch, display, map, theme, country, work, bbox, countriesPoints])
  // STOP : useEffect()

  const mapWidth = 55 // Desktop ( % )
  const mapHeight = 35 // Mobile ( % )

  return (
    <Fragment>
      <Box
        position="relative"
        display={{ xs: "block", md: "flex" }}
        flex={{ md: 100 }}
        height="100%"
        overflow="auto" // AppBar sticky like
      >
        {/* START : Search box */}
        <Box
          margin={2}
          position="absolute"
          top={0}
          left={0}
          zIndex={2}
          maxWidth={{
            xs: `calc(100% - ${theme.spacing(4)}px)`,
            md: `calc(${mapWidth}% - ${theme.spacing(4)}px)`,
          }}
        >
          <Search />
        </Box>
        {/* STOP : Search box */}

        {/* START : Map container */}
        <Box
          ref={el => (mapContainer.current = el)}
          position="relative"
          flex={{ md: mapWidth }}
          minHeight={{ xs: `${mapHeight}%`, md: "100%" }}
          style={{
            width: !display.sidebar && "100%",
            height: !display.sidebar && "100%",
          }}
          bgcolor="primary.light"
        >
          {loading && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              zIndex={1}
              // bgcolor="primary.light"
            >
              <Box padding={1}>
                <CircularProgress />
              </Box>
              <Box color="primary.contrastText">Chargement de la carte...</Box>
            </Box>
          )}

          <Box margin={1} position="absolute" bottom={0} right={0} zIndex={1}>
            <Box margin={1}>
              <Fab
                size="small"
                className={classes.button}
                onClick={() => {
                  console.log("SET_DISPLAY")
                  dispatch({
                    type: "SET_DISPLAY",
                    display: {
                      ...display,
                      sidebar: !display.sidebar,
                    },
                  })
                }}
              >
                <Hidden mdUp>
                  {display.sidebar ? <ChevronDownIcon /> : <ChevronUpIcon />}
                </Hidden>
                <Hidden smDown>
                  {display.sidebar ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </Hidden>
              </Fab>
            </Box>
          </Box>

          <Box margin={1} position="absolute" bottom={0} left={0} zIndex={1}>
            <Box margin={1}>
              <Fab
                size="small"
                className={classes.button}
                aria-label="zoom in"
                onClick={() => map.zoomIn()}
              >
                <ZoomInIcon />
              </Fab>
            </Box>
            <Box margin={1}>
              <Fab
                size="small"
                className={classes.button}
                aria-label="zoom out"
                onClick={() => map.zoomOut()}
              >
                <ZoomOutIcon />
              </Fab>
            </Box>
          </Box>
        </Box>

        {/* STOP : Map container */}

        {/* START : Sidebar container */}

        <Box
          position="relative"
          zIndex={1}
          boxShadow={3}
          flex={{ md: 100 - mapWidth }}
          minHeight="100%"
          overflow={{ xs: "initial", md: "auto" }}
          style={{
            backgroundColor: theme.palette.grey[200],
            display: !display.sidebar && "none",
          }}
        >
          {children}
          <Box position="absolute" top={0} right={0}>
            <IconButton
              onClick={() => {
                console.log("SET_DISPLAY")
                dispatch({
                  type: "SET_DISPLAY",
                  display: {
                    ...display,
                    sidebar: false,
                  },
                })
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {/* STOP : Sidebar container */}
      </Box>
    </Fragment>
  )
}

export default Map
