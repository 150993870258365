import React, { useState } from "react"

import { useTheme } from "@material-ui/styles"
import {
  IconButton,
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@material-ui/core"
import { Close as CloseIcon, HelpOutline as HelpIcon } from "@material-ui/icons"

const Popup = props => {
  const theme = useTheme()

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box>
      <Tooltip title={props.tooltip}>
        <IconButton color="secondary" size="small" onClick={handleOpen}>
          <HelpIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="lg">
        <IconButton
          style={{
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Box textAlign="center">
          <DialogTitle disableTypography>
            <Typography variant="h4">{props.title}</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{props.children}</DialogContentText>
          </DialogContent>
        </Box>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Popup
