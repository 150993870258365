import React, { Fragment, useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import {
  navigate,
  // Link as RouterLink
} from "gatsby"
import { useSelector, useDispatch } from "react-redux"

import { useTheme } from "@material-ui/styles"
import { Autocomplete } from "@material-ui/lab"
import {
  Box,
  Typography,
  Grid,
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Button,
  Divider,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  IconButton,
} from "@material-ui/core"
import {
  ExpandMore as ChevronBottomIcon,
  Search as SearchIcon,
  Restore as RestoreIcon,
  Close as CloseIcon,
} from "@material-ui/icons"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { format, isValid } from "date-fns"
import frLocale from "date-fns/locale/fr"

import countryToFlag from "../functions/countryToFlag"
import Popup from "../components/popup"

const Search = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const location = useLocation()

  const display = useSelector(state => state.display)
  const filter = useSelector(state => state.filter)
  const country = useSelector(state => state.country)

  const { countries, typologies, platforms } = useStaticQuery(
    graphql`
      query {
        countries: allCountriesJson {
          nodes {
            bbox
            properties {
              id
              isoAlpha2
              nameFr
              continent {
                properties {
                  id
                  bbox
                  nameFr
                }
              }
            }
          }
        }
        typologies: allWpTypology(limit:100,
        sort:
        {
          title: ASC
        }) {
                  nodes {
                    id
                    databaseId
                    title
                    acftypologie {
                      description
                    }
                  }
        }
        platforms: allWpPlatform(
          limit: 100
          sort:
        {
          title: ASC
        })
        {
          nodes {
            id
            databaseId
            title
            acfplateforme{
              description
            }
          }
        }
      }
    `
  )        
          // typologies: allWpTypology(
          //   first: 100
          //   where: { orderby: { field: TITLE, order: ASC } }
          // ) {
          //   nodes {
          //     id
          //     databaseId
          //     title
          //     acftypologie {
          //       description
          //     }
          //   }
          // }
          // platforms: allWpPlatform(
          //   first: 100
          //   where: { orderby: { field: TITLE, order: ASC } }
          // ) {
          //   nodes {
          //     id
          //     databaseId
          //     title
          //     acfplateforme {
          //       description
          //     }
          //   }
          // }


  // const { typologies, platforms } = wordpress

  const [count, setCount] = useState(null)

  // START : useEffect()
  useEffect(() => {
    let count = 0
    Object.keys(filter).forEach(key => {
      if (!filter[key]) {
        // console.log("null", key, filter[key])
      } else if (filter[key].length === 0) {
        // console.log("length", key, filter[key])
      } else if (key === "direction" || key === "afterWork"|| key === "beforeWork"|| key === "afterWriter"|| key === "beforeWriter") {
        // console.log("length", key, filter[key])
      } else {
        console.log("count", key, filter[key])
        count = count + 1
        console.log("SET_DISPLAY")
        dispatch({
          type: "SET_DISPLAY",
          display: {
            ...display,
            sidebar: true,
          },
        })
      }
    })
    setCount(count)
  }, [dispatch, filter]) // eslint-disable-line react-hooks/exhaustive-deps
  // STOP : useEffect()

  // START : useEffect()
  useEffect(() => {
    const area = country ? country : null
    if (area !== filter.area) {
      console.log("SET_FILTER")
      dispatch({
        type: "SET_FILTER",
        filter: {
          ...filter,
          area,
        },
      })
    }
  }, [dispatch, filter, country])
  // STOP : useEffect()

  // Merge countries in array
  let areas = []
  countries.nodes.forEach(node => {
    if (node.properties.id !== null) {
      areas = [...areas, node.properties]
    }
  })

  const handleChangeField = (fieldName, fieldValue) => {
    if (
      filter?.area?.alpha3 &&
      location.pathname !== "/" + filter?.area?.alpha3
    ) {
      navigate("/" + filter?.area?.alpha3)
    }
    console.log("SET_FILTER")
    dispatch({
      type: "SET_FILTER",
      filter: {
        ...filter,
        [fieldName]: fieldValue,
      },
    })
  }

  return (
    <ExpansionPanel
      expanded={display.search}
      onChange={() => {
        console.log("SET_DISPLAY")
        dispatch({
          type: "SET_DISPLAY",
          display: {
            ...display,
            search: !display.search,
          },
        })
      }}
    >
      <ExpansionPanelSummary
        expandIcon={<ChevronBottomIcon />}
        aria-controls="filter-panel"
        style={{ padding: theme.spacing(0, 2, 0, 2) }}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Box display="flex">
            <SearchIcon style={{ marginRight: theme.spacing(1) }} />
            <Typography>Rechercher</Typography>
          </Box>
          {count > 0 && (
            <Typography
              component="div"
              style={{ marginLeft: theme.spacing(3) }}
            >
              <Box color="primary.light">
                {count}
                {count < 2 ? ` filtre actif...` : ` filtres actifs...`}
              </Box>
            </Typography>
          )}
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item>
            <FormControl variant="outlined" fullWidth>
              <InputLabel
                htmlFor="titleField"
                // shrink={filter.title ? true : false}
              >
                Titre ou Auteur
              </InputLabel>
              <OutlinedInput
                id="titleField"
                label="Titre ou Auteur"
                defaultValue={filter.title || null}
                onBlur={e => {
                  const value = e.target.value === "" ? null : e.target.value
                  handleChangeField("title", value)
                }}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    const value = e.target.value === "" ? null : e.target.value
                    handleChangeField("title", value)
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      // onClick={e => {
                      //   const value = document.getElementById("titleField").value === "" ? null : document.getElementById("titleField").value
                      //   handleChangeField("title", value)
                      // }}
                      edge="end"
                    >
                      {<SearchIcon />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={35}
              />
            </FormControl>
          </Grid>

          <Grid item>
            <Autocomplete
              options={areas}
              groupBy={option => {
                // Group countries by continent
                return option.continent
                  ? option.continent.properties.nameFr.toUpperCase()
                  : ""
              }}
              autoHighlight
              getOptionLabel={option => option.nameFr}
              getOptionSelected={(option, value) =>
                value === undefined || value === option
              }
              renderOption={option => (
                <Fragment>
                  <Box component="span" paddingRight={1}>
                    {countryToFlag(
                      option.isoAlpha2 ? option.isoAlpha2 : option.id
                    )}
                  </Box>
                  {option.nameFr}
                </Fragment>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Localisation"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              value={
                filter.area
                  ? areas.find(area => area.id === filter.area.id)
                  : null
              }
              onChange={(e, value) =>
                value ? navigate("/" + value.id) : navigate("/")
              }
            />
          </Grid>

          <Grid item>
            <Box display="flex" alignItems="center">
              <Box width="100%">
                <Autocomplete
                  options={typologies.nodes}
                  autoHighlight
                  getOptionLabel={option => option.title}
                  getOptionSelected={(option, value) =>
                    value === undefined || value === option
                  }
                  renderOption={option => option.title}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Type d’œuvre"
                    />
                  )}
                  value={
                    filter.typologies
                      ? typologies.nodes.find(
                          typology => typology.id === filter.typologies.id
                        )
                      : null
                  }
                  onChange={(e, value) => {
                    handleChangeField("typologies", value)
                  }}
                />
              </Box>
              <Box marginLeft={1}>
                <Popup
                  tooltip="Ouvrir le glossaire"
                  title="Glossaire : types d'oeuvres"
                >
                  <Box textAlign="left">
                    <ul>
                      {typologies.nodes.map(typology => (
                        <li key={typology.id}>
                          <b>{typology.title}</b> : {typology.acftypologie.description}
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Popup>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Box display="flex" alignItems="center">
              <Box width="100%">
                <Autocomplete
                  options={platforms.nodes}
                  autoHighlight
                  getOptionLabel={option => option.title}
                  getOptionSelected={(option, value) =>
                    value === undefined || value === option
                  }
                  renderOption={option => option.title}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Plateforme"
                    />
                  )}
                  value={
                    filter.platforms
                      ? platforms.nodes.find(
                          platform => platform.id === filter.platforms.id
                        )
                      : null
                  }
                  onChange={(e, value) => {
                    handleChangeField("platforms", value)
                  }}
                />
              </Box>
              <Box marginLeft={1}>
                <Popup
                  tooltip="Ouvrir le glossaire"
                  title="Glossaire : plateformes"
                >
                  <Box textAlign="left">
                    <ul>
                      {platforms.nodes.map(platform => (
                        <li key={platform.id}>
                          <b>{platform.title}</b> : {platform.acfplateforme.description}
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Popup>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <KeyboardDatePicker
                    label="Après le"
                    value={filter.start || null}
                    onChange={date => {
                      const value = isValid(date)
                        ? format(date, "yyyy-MM-dd")
                        : date
                      handleChangeField("start", value)
                    }}
                    autoOk
                    style={{ width: "100%" }}
                    variant="inline"
                    inputVariant="outlined"
                    disableToolbar
                    format="dd / MM / yyyy"
                  />
                </Grid>
                <Grid item>
                  <KeyboardDatePicker
                    label="Avant le"
                    value={filter.stop || null}
                    onChange={date => {
                      const value = isValid(date)
                        ? format(date, "yyyy-MM-dd")
                        : date
                      handleChangeField("stop", value)
                    }}
                    autoOk
                    style={{ width: "100%" }}
                    variant="inline"
                    inputVariant="outlined"
                    disableToolbar
                    format="dd / MM / yyyy"
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <Button
          size="small"
          startIcon={<RestoreIcon />}
          color="secondary"
          onClick={() => {
            console.log("SET_FILTER")
            dispatch({
              type: "SET_FILTER",
              filter: {
                area: null,
                typologies: null,
                title: null,
                platforms: null,
                start: null,
                stop: null,
              },
            })
            navigate("/")

            // TEMP <=====================================================
            document.getElementById("titleField").value = null
            // TEMP <=====================================================
          }}
        >
          réinitialiser
        </Button>

        <Box color="text.secondary">
          <Button
            size="small"
            startIcon={<CloseIcon />}
            color="inherit"
            onClick={() => {
              console.log("SET_DISPLAY")
              dispatch({
                type: "SET_DISPLAY",
                display: {
                  ...display,
                  search: !display.search,
                },
              })
            }}
          >
            fermer
          </Button>
        </Box>
      </ExpansionPanelActions>
    </ExpansionPanel>
  )
}

export default Search
