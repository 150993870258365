const initialState = {
  display: {
    search: false,
    view: "rows",
    works: true,
    writers: true,
    sidebar: true,
  },
  filter: {
    area: null,
    title: "",
    typologies: null,
    platforms: null,
    start: null,
    stop: null,
    afterWork: null,
    beforeWork: null,
    afterWriter: null,
    beforeWriter: null,
    direction: "forward"
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_COUNTRY":
      return {
        ...state,
        country: action.country,
      }
    case "SET_WORK":
      return {
        ...state,
        work: action.work,
      }
    case "SET_WRITER":
      return {
        ...state,
        writer: action.writer,
      }
    case "SET_BBOX":
      return {
        ...state,
        bbox: action.bbox,
      }
    case "SET_COUNTRIES":
      return {
        ...state,
        countries: action.countries,
      }
    case "SET_DISPLAY":
      return {
        ...state,
        display: action.display,
      }
    case "SET_FILTER":
      return {
        ...state,
        filter: action.filter,
      }
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      }
    case "SET_COUNTRIES_POINTS":
      return {
        ...state,
        countriesPoints: action.countriesPoints,
      }
    default:
      return state
  }
}
