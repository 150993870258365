import React from "react"

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

// import { client } from "./client"
const client = new ApolloClient({
    cache: new InMemoryCache(),
    // uri: "https://weblitt.dev.msh-lse.fr/graphql"
    uri: "https://web.msh-lse.fr/weblitt/graphql"
  });
// eslint-disable-next-line react/display-name,react/prop-types
 const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)

export {wrapRootElement, client}

