import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useStaticQuery, graphql } from "gatsby"
// import { gql } from "apollo-boost"
// import { useQuery } from "@apollo/react-hooks"
import { gql, useQuery } from '@apollo/client'


export function useDispatchPoints() {
  const dispatch = useDispatch()

  const { allCountriesPointsJson } = useStaticQuery(
    graphql`
      query {
        allCountriesPointsJson {
          nodes {
            type
            geometry {
              type
              coordinates
            }
            properties {
              alpha3
            }
          }
        }
      }
    `
  )

  const QUERY = gql`
    query {
      countries: allCountries(
        first: 999

      ) {
        nodes {
          title
          acfpays {
            alpha3
            works: joinCountryWorks {
              ... on Work {
                title
              }
            }
            writers: joinCountryWriters {
              ... on Writer {
                title
              }
            }
          }
        }
      }
    }
  `
  // console.log("QUERY", QUERY.loc.source.body)
  const { data } = useQuery(QUERY, { errorPolicy: "ignore" })
  const countries = data?.countries.nodes

  useEffect(() => {
    let countriesPoint = []
    let emptyCountryCount = 0;
    countries &&
      countries.forEach(country => {
        // console.log(country)
        const countryAlpha3 = country.acfpays.alpha3
        const worksCount = country.acfpays?.works?.length || 0
        const writersCount = country.acfpays?.writers?.length || 0

        let countryPoint = allCountriesPointsJson.nodes.find(
          feature => feature.properties.alpha3 === countryAlpha3
        )
        if (countryPoint) {
          countryPoint = {
            ...countryPoint,
            properties: {
              ...countryPoint.properties,
              works: worksCount,
              writers: writersCount,
              total: worksCount + writersCount,
            },
          }
          if (countryPoint.properties.total !== 0) {
            countriesPoint = [...countriesPoint, countryPoint]
          } else
          {
            emptyCountryCount++;
          }

          if (countriesPoint.length + emptyCountryCount === countries.length) {
            // console.log("countriesPoint", countriesPoint)
            console.log("SET_COUNTRIES_POINTS")
            dispatch({
              type: "SET_COUNTRIES_POINTS",
              countriesPoints: countriesPoint || null,
            })
          }
        }
      })
  }, [dispatch, countries, allCountriesPointsJson])
}
