import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"

import { differenceInCalendarDays, parseISO, format } from "date-fns"

import { grey } from "@material-ui/core/colors"
import { useTheme, makeStyles } from "@material-ui/styles"
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Breadcrumbs,
  Typography,
  useMediaQuery,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core"
import {
  // Menu as MenuIcon,
  NavigateNext as ChevronRightIcon,
  Close as CloseIcon,
  PlayCircleOutline as VideoIcon,
  InfoOutlined as AboutIcon,
} from "@material-ui/icons"

import Link from "../components/link"

const Header = () => {
  const theme = useTheme()
  const work = useSelector(state => state.work)
  const writer = useSelector(state => state.writer)
  const country = useSelector(state => state.country)

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const [openTuto, setOpenTuto] = useState(false)
  const handleOpenTuto = () => {
    setOpenTuto(true)
  }
  const handleCloseTuto = () => {
    setOpenTuto(false)
  }

  const logger = {
    info: require('debug')('info'),
    warn: require('debug')('warn'),
    error: require('debug')('error'),
    debug: require('debug')('debug'),
    log:  require('debug')('log')
  }

  if (! logger.debug.enabled) console.log = (...args) => logger.debug(...args)
  console.info = (...args) => logger.info(...args)
  console.warn = (...args) => logger.warn(...args)
  console.error = (...args) => logger.error(...args)
  console.debug = (...args) => logger.debug(...args)


  useEffect(() => {
    if (typeof window !== "undefined") {
      const lastDate = localStorage.getItem("date")
      const nowDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
      localStorage.setItem("date", nowDate)
      if (!lastDate) {
        console.log("First visit !")
        setOpen(true)
      } else {
        const daysDiff = differenceInCalendarDays(
          parseISO(nowDate),
          parseISO(lastDate)
        )
        console.log("Days from last visit", daysDiff)
        if (daysDiff >= 1) {
          // console.log("Open popup (daysDiff >= 1)")
          setOpen(true)
        } else {
          // console.log("Ignore popup (daysDiff < 1)")
        }
      }
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 100)
        }
      }
      partners: allFile(filter: { relativePath: { regex: "/partners/" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  `)

  const isXS = useMediaQuery(theme => theme.breakpoints.down("xs"))

  const useStyles = makeStyles({
    breadcrumbs: {
      flexWrap: "nowrap",
    },
  })
  const classes = useStyles()

  return (
    <AppBar
      position="relative"
      color="primary"
      style={{ backgroundColor: theme.palette.primary.dark }}
    >
      <Toolbar>
        {/* <IconButton edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton> */}

        <Box flexGrow={1} marginRight={2} overflow="hidden">
          <Breadcrumbs
            classes={{
              ol: classes.breadcrumbs,
            }}
            separator={
              <ChevronRightIcon
                fontSize="small"
                style={{
                  color: grey[400],
                  // theme.palette.primary.dark
                }}
              />
            }
            color="inherit"
            aria-label="breadcrumb"
          >
            <Box paddingLeft={1}>
              <Link to={"/"}>
                <Img  image={data.logo.childImageSharp.gatsbyImageData} objectFit="contain"/>
              </Link>
            </Box>

            {!isXS && (work || writer) && country && (
              <Link to={"/" + country.id} color="inherit">
                {country.nameFr}
              </Link>
            )}

            {(work || writer || country) && (
              <Typography
                noWrap
                style={{
                  color: grey[400],
                  // color: theme.palette.primary.dark
                }}


              >
                {work ? work.title : writer ? writer.title : country?.nameFr}
              </Typography>
            )}
          </Breadcrumbs>
        </Box>

        <Box marginRight={1}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleOpenTuto}
            startIcon={<VideoIcon />}
          >
            tuto
          </Button>
          <Dialog
            open={openTuto}
            onClose={handleCloseTuto}
            fullWidth={true}
            maxWidth="lg"
          >
            <IconButton
              style={{
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
              }}
              onClick={handleCloseTuto}
            >
              <CloseIcon />
            </IconButton>

            <iframe
              title="tuto"
              frameBorder="0"
              width="1280"
              height="720"
              src={`https://www.youtube-nocookie.com/embed/uUApI01Rn9o?theme=dark&amp;autoplay=1&amp;autohide=0&amp;cc_load_policy=1&amp;modestbranding=1&amp;fs=0&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;mute=0&amp;loop=1`}
            />
          </Dialog>
        </Box>

        <Box>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleOpen}
            startIcon={<AboutIcon />}
          >
            à propos
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth="lg"
          >
            <IconButton
              style={{
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>

            <Box textAlign="center" padding={4}>
              <DialogTitle disableTypography>
                <Typography variant="h4">
                  Bienvenue sur la plateforme WEBLITT !
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Ce site est dédié à toutes les formes de littérature
                  francophone nativement numérique. Il est issu du projet «
                  Cartographie du Web littéraire francophone » mené par l’équipe
                  MARGE de l’université Jean Moulin-Lyon 3, en collaboration
                  avec le laboratoire ERIC et la MSH Lyon-St Etienne. Il
                  s’intègre au{" "}
                  <a
                    href="https://projet-lifranum.univ-lyon3.fr/projet"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    programme scientifique LIFRANUM
                  </a>{" "}
                  financé par l’ANR (2020-2023). Vous pouvez naviguer librement,
                  de pays en continent, à la découverte d’œuvres littéraires
                  créées pour un blog, un site, un ou plusieurs réseaux sociaux…
                  Des communautés, toujours en mouvement et recomposition,
                  apparaissent, qui, de lien en lien, tissent une nouvelle image
                  de la francophonie littéraire et culturelle. Et de la
                  littérature…
                </DialogContentText>
              </DialogContent>

              <DialogTitle disableTypography>
                <Typography variant="h4">Partenaires</Typography>
              </DialogTitle>
              <DialogContent>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  {data.partners.nodes
                    .sort(() => 0.5 - Math.random())
                    .map((partner, index) => (
                      <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                        <Img image={partner.childImageSharp.gatsbyImageData}/>
                      </Grid>
                    ))}
                </Grid>
              </DialogContent>
              <DialogTitle disableTypography>
                <Typography variant="h4">
                  Crédits et Mentions légales
                </Typography>
              </DialogTitle>

              <Box textAlign="left">
                <DialogContent>
                  <DialogContentText>
                    <Grid
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="stretch"
                      spacing={2}
                    >
                      <Grid item>
                        <Typography variant="h6">
                          Composition de l’équipe
                        </Typography>
                        <ul>
                          <li>Christian Cote</li>
                          <li>Jérôme Darmont</li>
                          <li>Lorraine Feugère</li>
                          <li>Jean-Pierre Fewou N’Goulouré</li>
                          <li>Frédérique Lozanorios</li>
                          <li>Belén Hernandez Marzal</li>
                          <li>Fanny Mezard</li>
                          <li>Alice Pantel</li>
                          <li>Lucien Perticoz</li>
                          <li>Julien Velcin</li>
                        </ul>
                      </Grid>

                      <Grid item>
                        <Typography gutterBottom variant="h6">
                          Directeur de la publication
                        </Typography>
                        <Typography gutterBottom>
                          Gilles Bonnet, Responsable scientifique du projet
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography gutterBottom variant="h6">
                          Hébergement
                        </Typography>
                        <Typography gutterBottom>
                          MSH Lyon St-Etienne (MSH LSE)
                          <br />
                          14 avenue Berthelot
                          <br />
                          69363 Lyon cedex 07 - France
                          <br />
                          Tél. 33 (0)4 72 72 64 64
                        </Typography>
                        <Typography gutterBottom>
                          USR 2005 | CNRS | Univ. Lyon 2 | Univ. Lyon 3 | Univ.
                          Lyon 1 | Univ. St-Etienne | ENS de Lyon | Sciences Po
                          Lyon
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography gutterBottom variant="h6">
                          Réalisation
                        </Typography>
                        <Typography gutterBottom>
                          Ce site a été développé par{" "}
                          <a
                            href="https://pro.guslyon.fr"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Gus Lyon
                          </a>{" "}
                          sous la responsabilité de Sonia Guérin-Hamdi (MSH LSE)
                          avec l’aide de Vincent Lombard (MSH LSE).
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography gutterBottom variant="h6">
                          Liens Hypertextes
                        </Typography>
                        <Typography gutterBottom>
                          Le site internet Weblitt.msh-lse.fr offre des liens
                          vers d’autres sites internet ou d’autres ressources
                          disponibles sur Internet.
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography gutterBottom variant="h6">
                          Clause de non-responsabilité
                        </Typography>
                        <Typography gutterBottom>
                          La responsabilité de la MSH Lyon St-Etienne ou de
                          l’université Jean-Moulin Lyon 3 et du laboratoire
                          MARGE ne peut, en aucune manière, être engagée quant
                          au contenu des informations figurant sur ce site ou
                          aux conséquences pouvant résulter de leur utilisation
                          ou interprétation.
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography gutterBottom variant="h6">
                          Protection des informations nominatives
                        </Typography>
                        <Typography gutterBottom>
                          Les informations disponibles sur ce site ne doivent
                          être utilisées qu'à des fins strictement personnelles.
                          La capture des écrans pour reconstitution ou
                          enrichissement d'une base de données nominatives est
                          contraire à la loi française, et est donc interdite
                          ainsi que toute utilisation à des fins commerciales et
                          publicitaires.
                        </Typography>
                        <Typography gutterBottom>
                          Conformément à la loi 78-17 "Informatique et libertés"
                          du 6 janvier 1978, vous disposez d’un droit d’accès,
                          de rectification et de suppression des données vous
                          concernant, en ligne sur ce site. Pour exercer ce
                          droit, vous pouvez vous adresser à …
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography gutterBottom variant="h6">
                          Contactez-nous
                        </Typography>
                        <Typography gutterBottom>
                          L’équipe projet WEBLITT est à votre disposition pour
                          tous vos commentaires ou suggestions.
                        </Typography>
                        <ul>
                          <li>
                            Contact scientifique :{" "}
                            <a
                              href="mailto:gilles.bonnet@univ-lyon3.fr"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              gilles.bonnet@univ-lyon3.fr
                            </a>
                          </li>
                          <li>
                            Contact technique :{" "}
                            <a
                              href="mailto:sonia.guerin-hamdi@cnrs.fr"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              sonia.guerin-hamdi@cnrs.fr
                            </a>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                  </DialogContentText>
                </DialogContent>
              </Box>
            </Box>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button onClick={handleClose} color="primary">
                Fermer
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
